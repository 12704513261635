<template>
  <footer class="app-footer mt-auto py-3 bg-light">
    <div class="container text-center">
      <p>
        <img src="../assets/logo-short.png" class="d-inline-block align-top" alt="Planet Accessories" height="35">&copy;
         {{ currentYear }} R&A Enterprise Luton&reg;. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
/* Add your custom styles here */
.app-footer {
  /* Customize the footer styles as per your design */
}
</style>
