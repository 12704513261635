<template>
  <b-navbar type="light" variant="info" sticky=true>
    <b-navbar-brand href="#">
      <img src="../assets/logo.png" class="d-inline-block align-top" alt="Planet Accessories" height="60">
    </b-navbar-brand>

      <!-- Navbar toggler button for mobile -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- Navbar items, which will be collapsed on mobile -->
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <!-- eBay link with grey logo and colored on hover -->
        <b-nav-item href="#" class="nav-link-with-icon ebay">
          <img src="../assets/ebay_logo.png" alt="eBay" class="nav-icon" />
        </b-nav-item>

        <!-- Amazon link with grey logo and colored on hover -->
        <b-nav-item href="#" class="nav-link-with-icon amazon">
          <img src="../assets/amazon_logo.png" alt="Amazon" class="nav-icon" />
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
  
<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem } from 'bootstrap-vue';

export default {
  name: 'AppNavbar',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
  },
};
</script>
  
<style>
/* Add your custom styles here */
/* Add your custom styles here */
.nav-link-with-icon {
  display: flex;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  color: #6c757d; /* Set the initial color to grey */
}

.nav-link-with-icon .nav-icon {
  margin-right: 10px;
  filter: grayscale(100%); /* Set the eBay and Amazon logos to grey */
}
.ebay .nav-icon {
  width: 100px;
  height: 50px;
}
.amazon .nav-icon {
  margin-top: 14px;
  width: 100px;
  height: 40px;
}

.nav-link-with-icon:hover {
  color: #007bff; /* Set the color to blue on hover */
}

.nav-link-with-icon:hover .nav-icon {
  filter: none; /* Remove the grayscale filter on hover */
}
</style>
