<template>
  <AppNavbar />
  <img alt="Vue logo" src="./assets/logo.png" class="logo-img">
  <AboutSection />
  <AppFooter/>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue';
import AppFooter from './components/AppFooter.vue';
import AboutSection from './components/AboutSection.vue'
export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    AboutSection,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.logo-img {
  width: 60%;
}
</style>
