<template>
    <div class="about-page">
      <div class="container">
        <h1>About Us</h1>
        <p>
          Welcome to Planet Accessories, your ultimate destination for top-quality tech accessories and gadgets. We are an
          online-only shop catering to customers in the UK.
        </p>
        <p>
          At Planet Accessories, we specialize in providing a curated selection of tech products, including phone cases,
          screen protectors, charging cables, wireless headphones, smartwatches, and much more. Our products are sourced from
          trusted brands known for their reliability and performance.
        </p>
        <p>
          As an online-only retailer, we are able to offer competitive prices and excellent customer service. Our goal is to
          provide you with a seamless shopping experience and deliver your orders promptly to your doorstep.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or inquiries, please don't hesitate to contact us at <strong><a href="mailto:info@planetaccessories.co.uk">info@planetaccessories.co.uk</a></strong>.
          Our friendly customer support team is available to assist you and ensure you have a delightful shopping experience.
        </p>
        <h3>Shop on eBay and Amazon UK</h3>
        <p>
          You can find our products on two popular platforms - eBay and Amazon UK. We maintain a strong presence on these
          marketplaces to ensure that you have multiple options for purchasing our tech accessories.
        </p>
        <p>
          To explore our product range and make a purchase, simply visit our stores on <strong><a href="#">eBay</a></strong> and <strong><a href="#">Amazon UK</a></strong>. We regularly update
          our listings with the latest products, so you can stay up-to-date with the newest tech accessories in the market.
        </p>
        <p>
          Thank you for choosing Planet Accessories for all your tech accessory needs. We look forward to serving you and
          providing you with the best products and service in the UK.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutSection',
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  .about-page {
    padding: 40px 0;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  </style>
  